import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import '../styles/app.scss';

const Register = () => (
    <>
        <SEO title="REGISTER WITH WRTS" />
        <HeaderMain />
        <HeroSingle pageTitle="REGISTER WITH WRTS" />
        <section className="page-section smallestwdt respitesec">
            <div className="iframe_container">
                <iframe
                    src="https://werockthespectrumnorthcalgary.wrtsfranchise.com/"
                    title="register form"
                    className="register_page_iframe"
                />
            </div>
        </section>
        <Footer />
    </>
);

export default Register;
